<!--
 * @Description: 公司详情
 * @Author: 琢磨先生
 * @Date: 2024-01-25 11:58:12
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-26 16:41:02
-->
<template>
  <el-dialog
    title="公司详情"
    v-model="visible"
    width="800px"
    draggable
    @closed="onClosed"
  >
    <div v-loading="loading">
      <el-descriptions :column="2">
        <el-descriptions-item label="公司名称">{{
          model.companyName
        }}</el-descriptions-item>
        <el-descriptions-item label="简称">{{
          model.nickname
        }}</el-descriptions-item>
        <el-descriptions-item label="法人">{{
          model.personName
        }}</el-descriptions-item>
        <el-descriptions-item label="法人电话">{{
          model.personMobile
        }}</el-descriptions-item>
        <el-descriptions-item label="主账号"
          >{{ model.adminName }} {{ model.adminMobile }}</el-descriptions-item
        >
        <el-descriptions-item label="状态">
          <el-link
            type="danger"
            size="small"
            v-if="model.checkStatus == 2"
            :underline="false"
            >{{ model.status_text }}</el-link
          >
          <el-link
            type="success"
            size="small"
            v-else-if="model.checkStatus == 1"
            :underline="false"
            >{{ model.status_text }}</el-link
          >
          <el-link
            type="primary"
            size="small"
            v-else-if="model.checkStatus == 3"
            :underline="false"
            >{{ model.status_text }}</el-link
          >
          <el-link size="small" v-else :underline="false">{{
            model.status_text
          }}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="注册时间">{{
          model.creationTime
        }}</el-descriptions-item>
        <el-descriptions-item label="专员">
          {{ model.counselorList?.map((x) => `${x.name}(${x.mobile})`).join("、") }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      model: {},
    };
  },
  props: {
    item: Object,
  },
  emits: ["closed"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.visible = true;
          this.loadDtl();
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClosed() {
      this.$emit("closed");
    },
    /**
     * 加载详情
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get(`admin/v1/company/dtl?id=${this.item.id}`)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
